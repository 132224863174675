import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewsletterHome from './NewsletterHome'; // Import the home page
import './App.css'; // Global CSS
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<NewsletterHome />} />
      </Routes>
    </Router>
  );
}

export default App;
